<template>
  <div class="Login">
    <div class="left">
      <img :src="require('assets/images/login_bg.png')" alt="" />
    </div>
    <div class="right">
      <div class="title">欢迎登录</div>
      <div class="tips">新用户登录自动注册</div>
      <div class="center">
        <div class="code-login" v-if="loginType == 1">
          <div class="phone">
            <span class="label">手机号 <span class="required">*</span> </span>
            <input
              placeholder="请输入手机号（必填）"
              v-model="phone"
              type="text"
            />
          </div>
          <div class="code">
            <span class="label">验证码 <span class="required">*</span></span>
            <input
              placeholder="请输入验证码（必填）"
              v-model="code"
              type="text"
            />
            <div class="sendCode" @click="sendVerif">
              {{
                verif_seconds == 60 ? "获取验证码" : `已发送(${verif_seconds})`
              }}
            </div>
          </div>
          <div class="phone" style="margin-top: 40px">
            <span class="label">邀请码</span>
            <input
              placeholder="请输入邀请码（选填）"
              v-model="InvitationCode"
              disabled
              type="text"
            />
          </div>
        </div>
        <div class="wx-login" v-if="loginType == 2">
          <div class="code" style="height: 150px">
            <iframe
              v-if="isiframe"
              :src="`https://open.weixin.qq.com/connect/qrconnect?appid=wx0064c361acc150b7&scope=snsapi_login&redirect_uri=https://www.writemall.com/AuthRedirect?InvitationCode=${InvitationCode}&state=bind&login_type=jssdk&self_redirect=default&styletype=&sizetype=&bgcolor=&rst=&style=black&href=data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7IGp1c3RpZnktY29udGVudDogY2VudGVyO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEzOHB4OyBtYXJnaW4tdG9wOiAwcHg7IGJvcmRlcjogMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ==`"
              frameborder="0"
              scrolling="no"
              width="138px"
              height="138px"
              target="_top"
              sandbox="allow-scripts allow-top-navigation"
            ></iframe>
          </div>

          <div
            class="againGet"
            @click="
              () => {
                isiframeFun();
                isiframe = false;
              }
            "
          >
            重新获取
          </div>
          <div class="wx-tips">
            <img :src="require('assets/images/login/weixin.png')" alt="" />
            打开微信扫码即可登录
          </div>
        </div>
      </div>
      <div class="loginBtn" @click="verifLogin">登录</div>
      <div class="icon" @click="loginType = loginType == 1 ? 2 : 1">
        <img
          :src="
            loginType == 1
              ? require('assets/images/wx-blue.png')
              : require('assets/images/phone-blue.png')
          "
          alt=""
        />
        <div class="text">
          {{ loginType == 1 ? "微信登录" : "手机登录" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  codeLogin,
  getuserInfo,
  passwordLogin,
  getMobileAchCaptchas,
} from "@/api/service";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      loginType: 1, // 1为密码登录 2位验证码登录
      phone: "", // 手机号
      code: "", //验证码
      codeId: "", //验证码ID
      InvitationCode: "", //邀请码

      verif_seconds: 60, //验证码倒计时
      timer: null, //计时器
      isiframe: true, //显示微信登录二维码
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.code) {
      this.InvitationCode = this.$route.query.code;
    }
  },
  methods: {
    //重新获取二维码
    isiframeFun() {
      setTimeout(() => {
        this.isiframe = true;
      }, 800);
    },
    //获取用户信息
    async getUserinfo() {
      try {
        const res = await getuserInfo();
        if (res.code === 200) {
          this.$store.commit("setUserInfo", res.data);
          console.log(res);
        }
      } catch (e) {
        this.$message.error("获取用户信息失败");
      }
    },
    //发送验证码
    sendVerif() {
      let reg = /^1[3|4|5|7|8]\d{9}$/;
      if (this.phone == "") {
        this.$message({
          type: "error",
          message: "手机号不能为空",
        });
        return;
      }
      if (!reg.test(this.phone)) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号",
        });
        return;
      }
      if (this.timer) {
        return;
      }
      const data = {
        type: 5,
        mobile: this.phone,
      };
      this.setVerifTime();
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.codeId = res.data.codeId;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.verif_seconds > 0) {
            this.verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.verif_seconds = 60;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    //验证码登录
    async verifLogin() {
      let reg = /^1[3|4|5|7|8]\d{9}$/;
      if (this.codeId == "") {
        return this.$message({
          type: "error",
          message: "请获取验证码!",
        });
      }
      if (!reg.test(this.phone)) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号!",
        });
        return false;
      }
      if (!this.code) {
        return this.$message({
          type: "error",
          message: "请输入验证码!",
        });
      }
      const data = {
        mobile: this.phone,
        code: this.code,
        id: this.codeId,
        inviteCode: this.InvitationCode,
      };

      try {
        let res = await codeLogin(data);
        if (res) {
          let data = res;
          localStorage.setItem("auth_token", JSON.stringify(data.access_token));

          data = JSON.stringify(data);
          //保存token
          Cookies.set("auth_token", data, {
            expires: 7,
            path: "/",
            domain: ".writemall.com",
          });
          this.$message({
            type: "success",
            message: "登录成功!",
          });
          this.getUserinfo();

          // window.location.reload();
          // window.location.reload(true);
          this.$router.push("/indexPage");
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: "验证码错误!",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Login {
  position: absolute;
  top: 0px;
  left: 0%;
  height: 100vh;
  width: 100%;
  display: flex;
  .left {
    width: 1220px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    padding: 157px 140px 0 160px;
    .title {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
    .tips {
      margin-top: 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .center {
      // margin-top: 62px;
      margin: 62px 0 70px;
      .code-login {
        .required {
          color: #ff0000;
          position: absolute;
          top: 10px;
          left: -6px;
        }
        .phone {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          span {
            text-align: center;
            flex: 1;
          }
          input {
            padding-left: 12px;
            width: 342px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            box-sizing: border-box;
          }
        }
        .code {
          position: relative;
          display: flex;
          align-items: center;
          span {
            text-align: center;
            flex: 1;
          }

          input {
            padding-left: 12px;
            width: 205px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            box-sizing: border-box;
          }
          .sendCode {
            cursor: pointer;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 127px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #4588ff;
          }
        }
      }
      .wx-login {
        text-align: center;
        .code {
          /* prettier-ignore */
          // height: 150px;
        }
        .againGet {
          font-size: 13px;
          color: #4588ff;
          cursor: pointer;
          margin-top: 10px;
        }
        .wx-tips {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .loginBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 50px;
      background: #4588ff;
      border-radius: 25px;
      margin: 0 auto;
      cursor: pointer;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .icon {
      text-align: center;
      cursor: pointer;
      // width: 60px;
      margin: 51px auto;
      img {
        width: 48px;
        height: 48px;
      }
      .text {
        // width: 56px;
        // height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #4588ff;
        margin-top: 9px;
      }
    }
  }
}
</style>